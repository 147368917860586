<script>
import Layout from "../../layouts/auth";
import { authMethods, notificationMethods } from "@/state/helpers";
import { mapState } from "vuex";

import appConfig from "@/app.config";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import axios from "@/services/index";
import authSvc from "@/services/auth";
import { toast } from "vue3-toastify";

/**
 * Login component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      toastCountdown: 2000,
    };
  },
  validations: {
    username: {
      required: helpers.withMessage("Username is required", required),
    },
    password: {
      required: helpers.withMessage("Password is required", required),
    },
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        authSvc
          .login(this.username, this.password)
          .then((resp) => {
            if (resp.status === 200) {
              const uuid = resp.data.data.uuid;
              const username = resp.data.data.username;
              const role = resp.data.data.role;
              const accessToken = resp.data.data.access_token;
              const user = { uuid, access_token: accessToken, username, role };

              toast.success("Login Successfully", {
                autoClose: localStorage.getItem("toastCountdown"),
              });

              localStorage.setItem("access_token", accessToken);
              localStorage.setItem("userInfo", JSON.stringify(user));
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${accessToken}`;
              this.$router.push("/");
            } else {
              toast.error("The username or password is incorrect.", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
              // this.authError = 'The username or password is incorrect.';
              // this.isAuthError = true;
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("The username or password is incorrect.", {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          });
      }
    },
  },
  mounted() {
    localStorage.setItem("toastCountdown", 2000);
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">KM Call Center</h5>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/profile-img.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/images/logo.png" alt height="34" />
                  </span>
                </div>
              </router-link>
            </div>
            <b-alert
              v-model="isAuthError"
              variant="danger"
              class="mt-3"
              dismissible
              >{{ authError }}</b-alert
            >
            <div
              v-if="notification.message"
              :class="'alert' + notification.type"
            >
              {{ notification.message }}
            </div>

            <b-form class="p-2" @submit.prevent="tryToLogIn">
              <b-form-group
                class="mb-3"
                id="input-group-1"
                label="Username"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="username"
                  type="text"
                  placeholder="Enter username"
                  :class="{
                    'is-invalid': submitted && v$.username.$error,
                  }"
                ></b-form-input>
                <div
                  v-for="(item, index) in v$.username.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="input-group-2"
                label="Password"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="password"
                  type="password"
                  placeholder="Enter password"
                  :class="{
                    'is-invalid': submitted && v$.password.$error,
                  }"
                ></b-form-input>
                <div
                  v-if="submitted && v$.password.$error"
                  class="invalid-feedback"
                >
                  <span v-if="v$.password.required.$message">{{
                    v$.password.required.$message
                  }}</span>
                </div>
              </b-form-group>
              <!-- <b-form-checkbox
                class="form-check me-2 mt-0"
                id="customControlInline"
                name="checkbox-1"
                value="accepted"
                unchecked-value="not_accepted"
                >Remember me
              </b-form-checkbox> -->
              <div class="mt-3 d-grid">
                <b-button type="submit" variant="primary" class="btn-block"
                  >Log In</b-button
                >
              </div>
              <!-- <div class="mt-4 text-center">
                <router-link to="/forgot-password" class="text-muted">
                  <i class="mdi mdi-lock me-1"></i> Forgot your password?
                </router-link>
              </div> -->
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>© {{ new Date().getFullYear() }} KM Call Center</p>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
